import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { apiURL } from '../_helper/url';
import { SearchClientAPIResponse } from './_interface/client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  apiURL: any = localStorage.getItem("urlData"); //URL DA API
  apiURLTemp: any = apiURL.urlTemp; //URL DA API

  constructor(private http: HttpClient) { }

  //---------------------------------------------------------------
  //Obter Clientes
  getClients() {
    return this.http.get<any>(`${this.apiURL}/client`)
  }


  //---------------------------------------------------------------
  //Obter Unico Cliente
  getUniqueClient(id: number) {
    return this.http.get<any>(`${this.apiURL}/client/` + id)
  }

  //---------------------------------------------------------------
  //Criar Cliente
  create(form: FormGroup) {

    const formData = new FormData();
    formData.append('first_name', form.value.first_name);
    formData.append('last_name', form.value.last_name);
    !form.value.birthday || form.value.birthday == null ? delete form.value.birthday : formData.append('birthday', form.value.birthday);
    formData.append('phone', form.value.phone);
    formData.append('email', form.value.email);
    formData.append('id_profession', form.value.id_profession);
    // formData.append('username', form.value.username);
    formData.append('zip_code', form.value.zip_code);
    formData.append('street', form.value.street);
    formData.append('district', form.value.district);
    formData.append('complement', form.value.complement);
    formData.append('number', form.value.number);
    formData.append('id_city', form.value.id_city);
    formData.append('cellphone', form.value.cellphone);
    form.value.cpf ? formData.append('cpf', form.value.cpf) : delete form.value.cpf;
    formData.append('nationality', form.value.nationality);
    formData.append('pep', form.value.pep);
    if (form.value.id_company) {
      formData.append('id_company', form.value.id_company);
    }
    formData.append('document_type', form.value.document_type);
    formData.append('document_number', form.value.document_number);
    formData.append('expiration_date', form.value.expiration_date);
    formData.append('issue_date', form.value.issue_date);
    form.value.files.forEach(files => formData.append('files', files, files.name));
    return this.http.post<any>(`${this.apiURL}/client/createSender`, formData);
  }

  createClient(form: FormGroup) {

    const formData = new FormData();
    formData.append('first_name', form.value.first_name);
    formData.append('last_name', form.value.last_name);
    formData.append('birthday', form.value.birthday);
    formData.append('phone', form.value.phone);
    formData.append('email', form.value.email);
    formData.append('username', form.value.username);
    formData.append('zip_code', form.value.zip_code);
    formData.append('street', form.value.street);
    formData.append('district', form.value.district);
    formData.append('complement', form.value.complement);
    formData.append('number', form.value.number);
    formData.append('id_city', form.value.id_city);
    formData.append('nationality', form.value.nationality);
    formData.append('pep', form.value.pep);
    formData.append('id_job_title', form.value.id_job_title);
    formData.append('id_occupation', form.value.id_occupation);
    formData.append('id_profession', form.value.id_profession);
    formData.append('employer', form.value.employer);
    formData.append('employer_address', form.value.employer_address);
    formData.append('employer_phone', form.value.employer_phone);
    formData.append('type', form.value.type);

    if (form.value.id_company) {
      formData.append('id_company', form.value.id_company);
    }
    formData.append('document_type', form.value.document_type);
    formData.append('document_number', form.value.document_number);
    formData.append('expiration_date', form.value.expiration_date);
    formData.append('issue_date', form.value.issue_date);
    form.value.files ? form.value.files.forEach(files => formData.append('files', files, files.name)) : null;
    // return this.http.post<any>(`${this.apiURL}/client/createSender`, formData);
    return this.http.post<any>(`${this.apiURL}/client`, formData);
  }

  public getOccupation() {
    return this.http.get<any>(`${this.apiURL}/client/occupations`);
  }

  public getProfessions() {
    return this.http.get<any>(`${this.apiURL}/client/profession`);
  }

  public getJobs(id_profession: any) {
    return this.http.get<any>(`${this.apiURL}/client/job/title/${id_profession}`);
  }

  // createClient(form: FormGroup) {
  //   delete form.value.state;
  //   delete form.value.state;
  //   return this.http.post<any>(`${this.apiURL}/client`, form.value);
  // }

  //---------------------------------------------------------------
  //Criar Cliente - Remetente
  createBeneficiary(form: FormGroup) {
    let formValue: any = form.value;
    delete formValue.state;
    delete formValue.id_country;
    formValue.nationality ?formValue.nationality :delete formValue.nationality;

    return this.http.post<any>(`${this.apiURL}/client/createBeneficiary`, formValue)
  }

  // Create beneficiary account
  createAccount(form: any) {
    return this.http.post<any>(`${this.apiURL}/client/createUserBankAccount`, form)
  }

  //---------------------------------------------------------------
  //Enviar Documento de cliente
  sendDocument(form: FormGroup) {
    const formData = new FormData();
    formData.append('id_user', form.value.id_user);
    formData.append('number', form.value.number);
    formData.append('type', form.value.type);
    form.value.expiration_date ? formData.append('expiration_date', form.value.expiration_date) : null;
    form.value.issue_date ? formData.append('issue_date', form.value.issue_date) : null;
    form.value.files ? form.value.files.forEach(files => formData.append('files', files, files.name)) : null;


    return this.http.post<any>(`${this.apiURL}/client/sendDocument`, formData)
  }

  //---------------------------------------------------------------
  //Editar Cliente
  update(formGroup: FormGroup, id: number) {
    delete formGroup.value.id_country;
    delete formGroup.value.id_state;
    delete formGroup.value.id_company;
    for (const chave in formGroup.value) {
      if (formGroup.value.hasOwnProperty(chave)) {
        if (formGroup.value[chave] == "" || formGroup.value[chave] == null) {
          delete formGroup.value[chave];
        }
      }
    }
    return this.http.patch<any>(`${this.apiURL}/client/` + id, formGroup.value)
  }

  getSumaryOfDay(): Observable<any> {
    return this.http.get<any>(`${this.apiURL}/company/summaryOfDay`)
  }

  // Get cliente by name and date birthday
  getClientByName(name: string, birthday: Date) {
    return this.http.get<any>(`${this.apiURL}/client/${name}/${birthday.toISOString().split('T')[0]}`)
  }

  // search client
  search(params: any): Observable<any> {
    delete params.user_id;
    delete params.user_name;
    !params?.name ? delete params?.name : false;
    !params?.email ? delete params?.email : false;
    !params?.phone ? delete params?.phone : false;
    !params?.nationality ? delete params?.nationality : false;
    !params?.start_date ? delete params?.start_date : false;
    !params?.end_date ? delete params?.end_date : false;
    !params?.birthday ? delete params?.birthday : false;

    return this.http.get<SearchClientAPIResponse>(`${this.apiURL}/client/search`, {
      params: {
        ...params
      }
    })
  }

  // search client by sender
  searchBeneficiaryBySender(params: any): Observable<any> {
    return this.http.get<SearchClientAPIResponse>(`${this.apiURL}/client/search/beneficiary/by/sender`, {
      params: {
        ...params
      }
    })
  }

  // search client
  searchSenderDirect(params: any): Observable<any> {
    !params?.name ? delete params?.name : false;

    return this.http.get<SearchClientAPIResponse>(`${this.apiURL}/client/search/direct`, {
      params: {
        ...params
      }
    })
  }

  // search client
  searchBeneficiaryDirect(params: any): Observable<any> {
    !params?.name ? delete params?.name : false;

    return this.http.get<SearchClientAPIResponse>(`${this.apiURL}/client/search/direct`, {
      params: {
        ...params
      }
    })
  }

  //---------------------------------------------------------------
  //Obter colaboradores
  getCollaborators() {
    return this.http.get<any>(`${this.apiURL}/client/collaborator/list`)
  }

  // Get type of documents
  getDocumentsTypes() {
    return this.http.get<any>(`${this.apiURL}/client/documentType`)
  }

  getDocumentsTypesSender() {
    return this.http.get<any>(`${this.apiURL}/client/documentTypeSender`)
  }

  getAgentsByName(name: string) {
    name == '' ? name = null : false;
    return this.http.get<any>(`${this.apiURL}/users/listUsers/AGENT/${name}`)
  }

  //---------------------------------------------------------------
}
